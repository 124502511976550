import React, { useEffect, useState } from 'react';
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { DefaultGraphType } from 'types/DefaultGraphType';
import { gql, useLazyQuery } from '@apollo/client';

interface I18nType extends DefaultGraphType {
  ko: string;
  en?: string;
  zh?: string;
  jp?: string;
  vn?: string;
}

// 다국어 조회
const GET_I18N_LIST = gql`
  query GetI18nList($request: GetI18nListInput ) {
    i18nList : getI18nList(request: $request) {
      id
      ko
      en
      zh
      jp
      vn
    }
  }
`;

const SetI18n = () => {
  const { i18n } = useTranslation();
  const [fnGetI18nData, { data: i18nRes }] = useLazyQuery(GET_I18N_LIST);
  const [readyI18n, setReadyI18n] = useState<boolean>(false);
  const router = useRouter();
  
  useEffect(() => {
    if (router.locale !== "ko") {
      if (readyI18n && i18nRes) {
        init();
      } else {
        fnGetI18nData({variables: {request: {limit: 0}}})
        setReadyI18n(true);
      }
    }
  },[i18nRes]);

  useEffect(() => {
    if (router.locale !== "ko" && i18nRes) {
      init();
    }
  },[router]);

  const init = () => {
    const i18nList:Array<I18nType> = i18nRes?.i18nList;
    i18nList.forEach((data:I18nType) => {
      i18n.addResource("ko", "common", data.ko, data.ko);
      i18n.addResource("en", "common", data.ko, data.en || data.ko);
      i18n.addResource("zh", "common", data.ko, data.zh || data.ko);
    });
    if (i18n) {
      i18n.init();
    }
  }

  return <React.Fragment></React.Fragment>;
}

export default SetI18n;
